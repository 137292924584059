import { render, staticRenderFns } from "./DepartmentPositionCell.vue?vue&type=template&id=6bd1bc7f&"
import script from "./DepartmentPositionCell.vue?vue&type=script&lang=ts&"
export * from "./DepartmentPositionCell.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports