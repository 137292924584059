



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IBlock } from './types';


@Component
export default class DossierTextBlockView extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: true,
    })
    public readonly block!: IBlock;

    @Prop({
        type: Boolean,
        required: false,
        default: () => false,
    })
    public readonly collapsible!: boolean;
    // endregion


    public get collapsed(): boolean { return this.block.collapsed; }
    private get content(): string { return this.block.content; }
    private get children(): Array<IBlock> { return this.block.children; }
    private get isLeaf(): boolean { return (this.children.length === 0); }


    private collapse() { this.block.collapse(); }
    private expand() { this.block.expand(); }

    private toggleCollapsed() {
        if (this.collapsed) this.expand();
        else this.collapse();
    }


    private get componentClass(): unknown { return DossierTextBlockView; };


    private currentTransition: ('enter' | 'leave' | null) = null;

    private initEnterTransition(el: HTMLElement) {
        this.currentTransition = 'enter';
        el.style.height = '0';
    }

    private enterTransitionStarted(el: HTMLElement) {
        setTimeout(() => {
            if (this.currentTransition === 'enter') {
                el.style.height = `${el.scrollHeight}px`;
            }
        });
    }

    private resetAfterEnterTransition(el: HTMLElement) {
        if (this.currentTransition === 'enter') {
            this.currentTransition = null;
            el.style.height = `${el.scrollHeight + 50}px`;
            setTimeout(() => {
                el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
                el.style.height = '';
            });
        }
    }

    private initLeaveTransition(el: HTMLElement) {
        el.style.height = `${el.scrollHeight}px`;
        this.currentTransition = 'leave';
    }

    private leaveTransitionStarted(el: HTMLElement) {
        if (this.currentTransition === 'leave') {
            setTimeout(() => {
                el.style.height = '0';
            });
        }
    }

    private resetAfterLeaveTransition(el: HTMLElement) {
        if (this.currentTransition === 'leave') {
            this.currentTransition = null;
            el.style.height = '';
        }
    }
}
