























































































































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { paramsSerializer } from '../common';
import { Comp, Department } from '../types';


/**
 * Строка таблицы
 */
interface IRow {
    /**
     * ID
     */
    id: number;

    /**
     * Данные, полученные из сервера
     */
    data: Department;

    /**
     * Признак "строка выделена"
     */
    selected: boolean;

    /**
     * Уровень строки
     */
    level: number;

    /**
     * Родительская запись
     */
    parent?: IRow;

    /**
     * Признак "Нет детей"
     */
    leaf: boolean;

    /**
     * Признак "Развернута"
     */
    expanded: boolean;

    /**
     * Дочерние записи
     */
    children?: IRow[];
}


const modelChangeEvent = 'change';


@Component
export default class GaDepartmentSelection extends Vue {
    @Prop({
        type: Number,
        required: true,
        validator(value: any): boolean {
            if (typeof value !== 'number') {
                console.error('"gaId" is not number', value);
                return false;
            }

            return true;
        }
    })
    public readonly versionId!: number;

    @Prop({
        type: Array,
        required: true,
    })
    public readonly departmentsId!: Department[];

    @Model(modelChangeEvent, {
        type: Array,
        required: false,
        default: () => [],
        validator(value: any): boolean {
            if (!Array.isArray(value)) {
                console.error('"value" is not array', value);
                return false;
            }

            for (let i = 0; i < value.length; i++) {
                const item = value[i];
                if (!(item instanceof Object)) {
                    console.error(`"value[${i}]" is not object`, value);
                    return false;
                }

                if (typeof item.id !== 'number') {
                    console.error(`"value[${i}].id" is not number`, value);
                    return false;
                }

                if (typeof item.nameKk !== 'string') {
                    console.error(`"value[${i}].nameKk" is not string`, value);
                    return false;
                }

                if (typeof item.nameRu !== 'string') {
                    console.error(`"value[${i}].nameRu" is not string`, value);
                    return false;
                }
            }

            return true;
        }
    })
    public readonly value!: Department[];

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly multiple!: boolean;

    @Prop({
        type: Boolean,
        default: false,
        required: false
    })
    public readonly selectOnRowClick!: boolean;


    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('value', () => {
            if (this.selectedGaDepartments !== this.value) {
                this.selectedGaDepartments = this.value;
                this.refreshRows();
            }
        });
        this.selectedGaDepartments = this.value;

        this.$watch('selectedGaDepartments', () => {
            if (this.value !== this.selectedGaDepartments) {
                this.$emit(modelChangeEvent, this.selectedGaDepartments);
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.reloadRows();
    }


    private get fields(): Comp.TableFieldDef[] {
        return [
            // Кнопка выбора
            {
                key: 'selected',
                label: ''
            },

            // ID
            {
                key: 'data.id',
                label: this.getTranslate('table_fields.id')
            },

            // Название на казахском
            {
                key: 'data.nameKk',
                label: this.getTranslate('table_fields.name_kk')
            },

            // Название на русском
            {
                key: 'data.nameRu',
                label: this.getTranslate('table_fields.name_ru')
            }
        ];
    }

    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.ga_departments.ga_department_selection.${key}`, values));
    }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    /*
    private getGaDepartmentFullText(department: IGaDepartment): string {
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            return department.nameKk;
        }
        return department.nameRu;
    }
    */

    /*
    private getGaText(department: IGaDepartment): string {
        const result = this.getGaDepartmentFullText(department);
        if (result.length > 50) {
            return result.substr(0, 47) + '...';
        }
        return result;
    }
    */


    private searchText = '';

    private loading = false;

    private selectedGaDepartments: Department[] = [];

    private get selectedGaDepartmentIds(): number[] {
        const result: number[] = [];

        this.selectedGaDepartments.forEach(department => {
            const id = department.id;
            if (id !== null) {
                result.push(id);
            }
        });

        return result;
    }

    private get selectedDepartmentsIds(): number[] {
        const result: number[] = [];

        this.departmentsId.forEach(department => {
            const id = department.id;
            if (id !== null) {
                result.push(id);
            }
        });

        return result
    }

    private rootRows: IRow[] = [];

    private get visibleRows(): IRow[] {
        const result: IRow[] = [];

        const add = (rows: IRow[]) => {
            rows.forEach(row => {
                result.push(row);
                if ((!row.leaf) && row.expanded && (row.children !== undefined)) {
                    add(row.children);
                }
            });
        };
        add(this.rootRows);

        return result;
    }

    private refreshRows() {
        const update = (rows: IRow[], level: number) => {
            rows.forEach(row => {
                row.selected = this.selectedGaDepartmentIds.includes(row.id);
                row.level = level;

                if (row.children !== undefined) {
                    update(row.children, level + 1);
                }
            });
        };
        update(this.rootRows, 0);

        this.rootRows = new Array(...this.rootRows);
    }

    private reloadRows() {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.rootRows = [];

        if (this.versionId === null) {
            console.error('Cannot load gu-departments - versionId is null');
            this.loading = false;
            return;
        }

        Ax<Department[]>(
            {
                url: `/api/budget/staffing_table/gu-departments/version/${this.versionId}/roots`,
                method: 'GET',
                params: {
                     'guDepartmentIds': this.selectedDepartmentsIds
                },
                paramsSerializer
            },
            departments => {
                const rootRows: IRow[] = [];

                departments.forEach(department => {
                    const row: IRow = {
                        id: department.id ?? -1,
                        data: department,
                        selected: this.selectedGaDepartmentIds.includes(department.id ?? -1),
                        level: 0,
                        leaf: department.leaf,
                        expanded: !!department.children
                    };

                    rootRows.push(row);

                    if (row.data.children?.isNotEmpty)
                    // Метод генерирует дочерние элементы для корневых департаментов и отображает их в модальном окне для удобной навигации.
                    this.generateChildRows(row)
                });

                this.rootRows = rootRows;
            },
            error => this.toast('danger', this.getTranslate('cannot_load_ga_departments'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }

    private generateChildRows(parent: IRow) {
        const add = (row: IRow) => {
            row.children =  [];

            for (const department of row.data.children ?? []) {
                const childRow: IRow = {
                    id: department.id ?? -1,
                    data: department,
                    selected: this.selectedGaDepartmentIds.includes(department.id ?? -1),
                    level: row.level + 1,
                    parent: row,
                    leaf: department.leaf,
                    expanded: !!department.children,
                };

                row.children.push(childRow);

                if (department.children) {
                    add(childRow);
                }
            }
        };

        add(parent);
    }

    private toggleSelection(department: Department) {
        let index: number | undefined;
        for (let i = 0; i < this.selectedGaDepartments.length; i++) {
            const selectedDepartment = this.selectedGaDepartments[i];
            if (selectedDepartment.id === department.id) {
                index = i;
                break;
            }
        }

        if (index === undefined) {
            if (this.multiple) {
                this.selectedGaDepartments.push(department);
            } else {
                this.selectedGaDepartments = [department];
            }
        } else {
            this.selectedGaDepartments.splice(index, 1);
        }
        this.refreshRows();
    }

    private toggleRowExpanded(row: IRow) {
        if (!row.leaf) {
            if (row.expanded) {
                row.expanded = false;
                this.refreshRows();
            } else if (row.children === undefined) {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                Ax<Department[]>(
                    {
                        url: `/api/budget/staffing_table/gu-departments/parent/${row.id}`
                    },
                    departments => {
                        const children: IRow[] = [];

                        departments.forEach(department => {
                            const childRow: IRow = {
                                id: department.id ?? -1,
                                data: department,
                                selected: this.selectedGaDepartmentIds.includes(department.id ?? -1),
                                level: row.level + 1,
                                parent: row,
                                leaf: department.leaf,
                                expanded: false
                            };
                            children.push(childRow);
                        });

                        row.children = children;
                        row.expanded = true;
                        this.refreshRows();
                    },
                    error => this.toast('danger', this.getTranslate('cannot_load_ga_departments'), error.toString()),
                    () => {
                        this.loading = false;
                    }
                );
            } else {
                row.expanded = true;
                this.refreshRows();
            }
        }
    }

    private onRowClicked(row: IRow) {
        if (this.selectOnRowClick) {
            this.toggleSelection(row.data);
        }
    }
}
