









































































import { Component, Prop, Vue, Model } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { Overlay } from '../components';
import I18n from '../I18n';
import DossierTextBlockView from './DossierTextBlockView.vue';
import { IBlock as IReadonlyBlock } from './types';
import {Comp, BudgetVariants} from '../types';

const modelChangeEvent = 'change';


// region Local types
interface IParsedLine {
    id: string;
    indent: number;
    content: string;
}

interface IBlock extends IReadonlyBlock {
    readonly line: IParsedLine;
}
// endregion


// region Utils
const i18n = new I18n('modules.budget.staffing_table.data.*DossierTextView*');

const parseLines = (text: string): Array<IParsedLine> => {
    const indentString = '.   ';
    const result: Array<IParsedLine> = [];
    const now = Date.now();

    text
        .split('\r\n')
        .filter(line => (line.length > 0))
        .forEach((line, index) => {
            let content = line;
            let indent = 0;

            while (content.startsWith(indentString)) {
                content = content.substr(indentString.length);
                indent++;
            }

            const parsedLine: IParsedLine = {
                id: `${now}-${index}`,
                indent,
                content,
            };

            result.push(parsedLine);
        });

    return result;
};

const createBlock = (line: IParsedLine): IBlock => {
    const result: IBlock = {
        line,
        collapsed: true,
        children: [],

        get id(): string { return this.line.id; },
        get isLeaf(): boolean { return (this.children.length === 0); },
        get content(): string { return (this.line.content); },
        get indent(): number { return (this.line.indent); },

        collapse() {
            this.collapsed = true;
            this.children.forEach(child => child.collapse());
        },

        expand() { this.collapsed = false; },
    };
    return Vue.observable(result);
};

const organizeLines = (lines: Array<IParsedLine>, startIndex: number): { block: IBlock; nextIndex: number; } => {
    const mainLine = lines[startIndex];
    const indent = lines[startIndex].indent;
    const childrenIndent = (indent + 1);

    const block: IBlock = createBlock(mainLine);

    let index = startIndex + 1;
    while (index < lines.length) {
        const nextLine = lines[index];
        if (nextLine.indent !== childrenIndent) break;

        const orgResult = organizeLines(lines, index);
        block.children.push(orgResult.block);
        index = orgResult.nextIndex;
    }

    return {
        block,
        nextIndex: index,
    }
};

const localStorageDateKeyPrefix = 'modules/budget/staffing-table/data/DossierTextView@date';
const localStorageLastDateKey = localStorageDateKeyPrefix + '@lastDate';

const createToday = (): Date => {
    const result = new Date();
    result.setHours(0, 0, 0, 0);
    return result;
};
// endregion


@Component({
    components: {
        DossierTextBlockView,
        Overlay,
    },
})
export default class DossierTextView extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
        },
    )
    public variantValue!: BudgetVariants | null;
    // endregion

    // region Свойства
  @Prop({
    type: Number,
    required: true,
  })
  public readonly employeeId!: number;
  // endregion

  @Prop({
    type: String,
    required: true,
  })
  public readonly idRegion!: String;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
  private created() {
    this.readDateFromCache();
    this.$watch('variantValue', (variantValue: BudgetVariants | null) => {
      if (this.variantValue !== null) {
        this.selectedBudgetVariant = variantValue;
      }
    });
    // endregion
    this.$watch('selectedBudgetVariant', (selectedBudgetVariant: BudgetVariants | null) => {
      if ((this.variantValue !== selectedBudgetVariant)) {
        this.$emit(modelChangeEvent, selectedBudgetVariant);
      }
    });
// endregion
    this.$watch('employeeId', () => {
      this.loadBlocks()
    });
      // endregion
    this.$watch('date', (newDate, oldDate) => {
      this.handleYearChange(newDate, oldDate)
      this.saveDateToCache();
    });
  }

    // noinspection JSUnusedLocalSymbols
   private mounted() {
        this.selectedBudgetVariant = this.variantValue
        this.reloadBudgetVariants() }
    // endregion


    // region Утилиты
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private toggleFullscreen() {
        const element = this.$el;
        if (element instanceof HTMLElement) {
            if (document.fullscreenElement === element) {
                document.exitFullscreen();
            } else {
                element.requestFullscreen();
            }
        }
    }

    // private get localStorageEmployeeDateKey(): string { return localStorageDateKeyPrefix + '@employee#' + this.employeeId; }
    // endregion


    // region Данные
    public date: Date = createToday();

    // region Год планового периода
    public curYear: number = createToday().getFullYear();

    private loadingBlocks = false;

    private blocks: Array<IBlock> = [];

   private selectedBudgetVariant: BudgetVariants | null = null;

   private budgetVariants: BudgetVariants[] = [];

   private loadingBudgetVariants = false;

  private get noBlocks(): boolean { return (this.blocks.length === 0); }

  private get noBudgetProgram(): boolean { return (this.budgetVariants.length === 0); }

  private get noSelectedVariant(): boolean { return (this.selectedBudgetVariant === null); }

  private get budgetVariantOptions(): Array<Comp.DropdownItemDef<BudgetVariants>> {
    return this.budgetVariants.map((variant) => {
      const uuid = variant.variantUuid;
      const title = this.i18n.locale.trim().toLowerCase() === 'kk'
          ? variant.nameKk ?? variant.nameRu
          : this.i18n.locale.trim().toLowerCase() === 'en'
              ? variant.nameEn ?? variant.nameRu
              : variant.nameRu;
      return { value: variant, text: `${title} [${uuid}]` };
    }).sort((v1, v2) => v1.text.localeCompare(v2.text));
  }

  // region Получение списка вариантов ( reloadBudgetVariants -  url также используется в файловом хранилище)
    private reloadBudgetVariants(): void {
        if (this.loadingBudgetVariants) {
            console.error('Cannot load dossier - another loading is running');
            return;
        }

        const selectedRegionCode = this.idRegion;
        const curYear = this.selectedCurYear ?? this.curYear
        let exists: boolean = false

        this.loadingBudgetVariants = true;
        const objectToFind = this.selectedBudgetVariant
        Ax<BudgetVariants[]>(
            {url: `/api/budget/staffing_table/budget-variants-storage?year=${curYear}&region-code=${selectedRegionCode}`},
            data => {
                this.budgetVariants = data;
                exists = data.some(obj => obj.id === objectToFind?.id);
            },
            error => {
                this.toast('danger', 'Loading budget variants', `Error while loading budget variants - ${error}`);
            },
            () => {
                if (exists) {
                    this.loadBlocks()
                } else {
                    this.blocks = []
                    this.selectedBudgetVariant = null }
                    this.loadingBudgetVariants = false;
            }
        );
  }

    private loadBlocks() {
        if (this.loadingBlocks) {
            console.error('Cannot load dossier - another loading is running');
            return;
        }

        const employeeId = this.employeeId;

        const curYear = this.selectedCurYear?? this.curYear

        const date = this.date.getTime();

       // region Дата начала действия нормативных показателей
       const dateStart = this.getDateStart

      if (!dateStart) {
        this.blocks = [];
        console.error('Cannot load dossier - because dateStart is empty from BudgetVariants');
        return;
      }

        this.loadingBlocks = true;
        this.blocks = [];
        Ax<string | null>(
            { url: `/api/budget/staffing_table/dossier/employee/${employeeId}?date=${date}&curYear=${curYear}&dateStart=${dateStart}`, responseType: 'text' },
            data => {
                const blocks: Array<IBlock> = [];
                if (data !== null) {
                    const parsedLines = parseLines(data);
                    if (parsedLines.length > 0) {
                        let index = 0;
                        while (index < parsedLines.length) {
                            const orgResult = organizeLines(parsedLines, index);

                            const block = orgResult.block;
                            blocks.push(block);

                            index = orgResult.nextIndex;
                        }
                    }
                }
                this.blocks = blocks;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_dossier', [employeeId]), error.toString()),
            () => { this.loadingBlocks = false; }
        );
    }

  private collapseAll() {
    this.blocks.forEach(block => block.collapse());
  }

  private selectedCurYear: null | number = this.variantValue?.year ?? null

  private get getCurYear() {
    return this.selectedCurYear ?? this.date.getFullYear()
  }

  private get getDateStart(): number | null {
    const dateStartNumber = this.selectedBudgetVariant?.dateStart
    if (dateStartNumber === null || dateStartNumber === undefined) {
      return null;
    }
    return new Date(dateStartNumber).getTime();
  }

  private handleBudgetVariantChange(value: BudgetVariants) {
    this.selectedBudgetVariant = value
    this.loadBlocks();
  }

  private handleYearChange(newDate: number, oldDate: number) {
    const previousDate = new Date(oldDate)
    const nextDate = new Date(newDate)
    if (nextDate.getFullYear() !== previousDate.getFullYear()) {
        this.curYear = nextDate.getFullYear()
        this.selectedCurYear = null
        this.reloadBudgetVariants();
    }
    if ((nextDate.getMonth() + 1 !== previousDate.getMonth() + 1 || nextDate.getDate() !== previousDate.getDate())
        && nextDate.getFullYear() == previousDate.getFullYear()) {
      this.loadBlocks();
    }
  }

  private getOptions(): Array<Comp.DropdownItemDef<number>> {
    const year = this.date.getFullYear()
    const result: Array<Comp.DropdownItemDef<number>> = [];
    const create = (value: number): Comp.DropdownItemDef<number> => {
      return {
        text: i18n.translate('planned_period', [value]),
        value: value
      };
    };

    for (let i = 0; i < 3; i++) {
      result.push(create(year - i));
    }
    return result
  }

    private changeCurYear(year: number) {
        this.curYear = year
        this.selectedCurYear = null
        this.reloadBudgetVariants();

    }

    private saveDateToCache() {
        const date = this.date;
        this.saveDateToCacheUsingKey(localStorageLastDateKey, date);
        // this.saveDateToCacheUsingKey(this.localStorageEmployeeDateKey, date);
    }

    private saveDateToCacheUsingKey(key: string, date: Date | null) {
        if (date === null) {
            try {
                localStorage.removeItem(key);
            } catch (e) {
                console.error(`Cannot remove date from local storage using key "${key}"`, e);
            }
        } else {
            try {
                localStorage.setItem(key, date.getTime().toString(10));
            } catch (e) {
                console.error(`Cannot remove date from local storage using key "${key}"`, e);
            }
        }
    }

    private readDateFromCache() {
        /*
        const employeeDate = this.readDateFromCacheUsingKey(this.localStorageEmployeeDateKey);
        if (employeeDate !== null) {
            this.date = employeeDate;
            return;
        }
        */

        const lastDate = this.readDateFromCacheUsingKey(localStorageLastDateKey);
        if (lastDate !== null) {
          this.date = lastDate;
          this.curYear = lastDate.getFullYear()
        }
    }

    private readDateFromCacheUsingKey(key: string): Date | null {
        try {
            const timeString: (string | null | undefined) = localStorage.getItem(key);
            // noinspection SuspiciousTypeOfGuard
            if (typeof timeString !== 'string') return null;

            const time = parseInt(timeString, 10);
            if ((!Number.isSafeInteger(time)) || (time.toString(10) !== timeString)) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error(`Invalid time string "${timeString}"`);
            }

            return new Date(time);
        } catch (e) {
            console.error(`Cannot read date from local storage using key "${key}"`, e);
            return null;
        }
    }
    // endregion
}
